import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

export default function JobDetails({ pageContext, ...props }) {
	return (
		<>
			<SEO
				title={pageContext.title}
				{...props}
			/>
			<Header />
			<div className="mx-auto max-w-5xl w-full  pb-0 xl:pb-20 pt-8  lg:pt-12 lg:pb-16 2xl:py-20 text-left">
				<h1 className="font-bold">{pageContext.title}</h1>
				<div className="job-content" dangerouslySetInnerHTML={{ __html: pageContext.content }} />
				<a href={`mailto:jobs@buddy.insure?subject=Application for the position of ${pageContext.title}&body=Please include your resume and a brief description of your relevant experience.`} className="buddy-btn-mobelux mt-12 w-full md:w-1/2 lg:w-2/3 mb-9">Apply Now</a>
			</div>
			<Footer />
		</>
	);
}
